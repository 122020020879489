import { useState, useEffect } from "react";
import api from "../api";

const useSpecializations = () => {
  const [specializations, setSpecializations] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSpecializations = async () => {
      try {
        const response = await api.get("/specializations");
        setSpecializations(response.data);
      } catch (error) {
        setError("Failed to fetch specializations.");
        console.error("Error fetching specializations:", error);
      }
    };
    fetchSpecializations();
  }, []);

  return { specializations, error };
};

export default useSpecializations;
