import React from "react";

const PricingPolicy = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">
        Welcome to BK Astro Service Private Limited!
      </h1>
      <p className="mb-6 text-gray-700">
        We are committed to providing clear and transparent pricing for all our
        astrological services. Our goal is to ensure you receive the best value
        for your investment in your spiritual and personal growth. Please take a
        moment to review our pricing policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Service Pricing
      </h2>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li className="font-semibold">Consultations:</li>
        <ul className="list-disc pl-5">
          <li>
            Vedic Astrology Consultations: Prices start at ₹300 per session.
          </li>
          <li>Tarot Reading Sessions: Prices start at ₹200 per session.</li>
          <li>Numerology Consultations: Prices start at ₹350 per session.</li>
          <li>Vastu Consultations: Prices start at ₹500 per session.</li>
        </ul>
        <li className="font-semibold mt-4">Kundli Services:</li>
        <ul className="list-disc pl-5">
          <li>
            Kundli Matching: Free basic service, with premium services starting
            at ₹1100.
          </li>
          <li>Detailed Kundli Analysis: Prices start at ₹800 per report.</li>
        </ul>
        <li className="font-semibold mt-4">Specialized Services:</li>
        <ul className="list-disc pl-5">
          <li>Personalized Reports: Prices start at ₹700 per report.</li>
          <li>Customized Remedies: Prices start at ₹1000 per service.</li>
          <li>Spiritual Store Products: Prices vary based on the product.</li>
        </ul>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Package Deals
      </h2>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>
          Basic Package: Includes 3 Vedic Astrology consultations at ₹1,500.
        </li>
        <li>
          Premium Package: Includes 5 consultations (any type) and a
          personalized report at ₹2,600.
        </li>
        <li>
          Ultimate Package: Includes unlimited consultations for a month and two
          personalized reports at ₹6,000.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Payment Methods
      </h2>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>Credit/Debit Cards: Visa, MasterCard, American Express</li>
        <li>Net Banking: All major banks</li>
        <li>UPI Payments: Google Pay, PhonePe, Paytm</li>
        <li>Wallets: Paytm, Amazon Pay</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Refund and Cancellation Policy
      </h2>
      <h3 className="text-xl font-semibold text-gray-800">Consultations:</h3>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>Refunds are not available for completed consultations.</li>
        <li>
          Cancellations made 24 hours before the scheduled session are eligible
          for a full refund.
        </li>
        <li>
          Cancellations made within 24 hours of the scheduled session are not
          eligible for a refund but can be rescheduled.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800">
        Reports and Remedies:
      </h3>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>
          Refunds are not available once the report or remedy has been
          delivered.
        </li>
        <li>
          In case of errors or discrepancies in the report, we offer a revision
          free of charge.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800">Package Deals:</h3>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>
          Packages are non-refundable but can be transferred to another user
          upon request.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Us</h2>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>
          Email:{" "}
          <a
            href="mailto:support@astrologerdaddy.com"
            className="text-blue-600 hover:underline"
          >
            support@astrologerdaddy.com
          </a>
        </li>
        <li>Phone: +91-9694933773</li>
        <li>
          Address: BK Astro Service Private Limited, 174d, Sector-6, Hanumangarh
          Jn., Hanumangarh, Rajasthan, India, 335512
        </li>
      </ul>

      <p className="text-gray-700">
        Thank you for choosing BK Astro Service Private Limited. We look forward
        to serving you and helping you on your journey to spiritual and personal
        enlightenment.
      </p>
    </div>
  );
};

export default PricingPolicy;
