import React from "react";

function AboutSection() {
  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      {/* Introduction Section */}
      <section className="mb-8">
        <h1 className="text-2xl font-bold text-center text-black mb-4">
          Astrologer Daddy
        </h1>
        <p className="text-gray-700 text-justify leading-relaxed">
          Astrology has been a topic of interest for centuries, capturing the
          curiosity of people everywhere. It helps you to learn more about
          yourself through the position of stars and planets when you were born.
          This knowledge can help you to make better decision and also help you
          to make your future bright. Life is full of uncertainties and many
          people choose the way to astrology for guidance, whether you are in
          tough time or facing big decision, astrology shows a path for making
          sense of what's happening in your life.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          It is often used to determine the compatibility between two
          individuals. It point outs some of the main issues that might occur in
          love relationship with the help of astrological charts (Kundli) of two
          partners. This helps couple to understand each other and also helps to
          build strong connection or relationship.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Reading horoscope, learning about zodiac signs, and exploring about
          astrological predictions are the activities which makes astrology an
          entertaining and enjoyable activity. It also encourage you to think
          about your life, future, career and goals. Sometimes astrology may not
          give all the answers, but it continues to capture people because it
          offers guidance and insights.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Astrology is very helpful during the time of shifting career, starting
          a new relationship, or moving to a new place, it provides a framework
          to understand these transitions to make them manageable. It inspires
          you to know about your potential towards new possibilities. With the
          help of your astrological chart will get to know about your hidden
          talent and interests. This will yield new opportunities and growth.
        </p>
      </section>

      {/* Understanding the Basics Section */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-black mb-4">
          Understanding the Basics of Astrology
        </h2>
        <p className="text-gray-700 text-justify leading-relaxed">
          Astrology is the study of cosmic objects such as Stars and Planets
          which are believed to influence human lives. According to astrology
          there are 9 planets in the solar system, known as Navaghrahas.
          Interestingly Earth is not included among these 9 planets. The 9
          planets are:
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Sun (Surya), Moon (Chandra), Mars (Mangala), Mercury (Budha), Jupiter
          (Brihaspati), Venus (Shukra), Saturn (Shani), Rahu (North node of the
          moon), Ketu (South node of the moon).
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Among these, some are friendly planets which give positive input to
          one's life whereas others like Rahu and Ketu have a negative nature.
          However, it must be said that their existence shall have an impact
          according to one's Kundli. For example, Ketu's presence isn't always
          harmful, amd Jupiter's presence isn't always beneficial.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          The placement of planets in the 12 houses of a Kundli is important.
          Each house represents different aspects of life. The first house
          represents the person's personality, and the fifth house, called
          'Putra Bhava', stands for creativity, playfulness, light-hearted fun,
          enjoyment, pleasure, and romance. Planet like Jupiter in the fifth
          house can enhance one's love life, while a challenging planet like
          Rahu might cause difficulties.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Astrology involves many components such as moon signs, numerology,
          tarots, and the like; in fact, it offers a broader dimension of inputs
          and guidance. One can either have the whole chart read through by an
          astrologer or even do the checking of the Kundli online. It is a
          powerful tool for self-discovery and personal growth.
        </p>
      </section>

      {/* How Astrology Can Impact Your Life Section */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-black mb-4">
          How Astrology Can Impact Your Life
        </h2>
        <ul className="list-disc list-inside text-gray-700 leading-relaxed">
          <li className="mb-2">
            <strong>Self-Awareness:</strong> Astrology helps you to know better
            about yourself. In your birth chart, you can get insights into your
            core personality traits, emotional needs, and motivations.
          </li>
          <li className="mb-2">
            <strong>Relationships:</strong> Astrology helps significantly in
            understanding relationships with people. Astrologers can determine,
            using birth charts, what real compatibility could be and what the
            potential areas of conflict are between two people.
          </li>
          <li className="mb-2">
            <strong>Timing:</strong> Astrologers come up with methods such as
            transits and progressions to let you know about those periods in
            your life when planetary positions are bad or good. This can help
            you in decision-making on matters such as changing your job,
            entering a relationship, or developing yourself.
          </li>
          <li className="mb-2">
            <strong>Guidance and Clarity:</strong> Many people turn to astrology
            for guidance during uncertain times. It can offer a sense of clarity
            and direction when you're facing important life decisions.
          </li>
        </ul>
      </section>

      {/* Get the Latest Daily Horoscope Predictions and Zodiac Insights */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold text-black mb-4">
          Get the Latest Daily Horoscope Predictions and Zodiac Insights
        </h2>
        <p className="text-gray-700 text-justify leading-relaxed">
          As a new brand in online astrology consultation, our objective is to
          make the best out of the visitor experience. We've quickly become a
          top source for detailed astrological information. Updates related to
          planetary movements, daily horoscopes with respect to all zodiac
          signs, forthcoming auspicious dates, and other associated aspects fall
          under this category.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Our daily horoscopes help you plan out your day according to what the
          stars predict, while our information on muhurats and other
          astrological services ensure you're always prepared with knowledge
          about the best times of the day. All of this information has been
          sourced in one place for your convenience. It has always been our
          vision to provide comprehensive astrology services, and we are
          continually getting better to serve you better.
        </p>
      </section>

      {/* Astrology Consultations & Services Online */}
      <section className="mb-8">
        <h3 className="text-xl font-semibold text-black mb-4">
          Astrology Consultations & Services Online
        </h3>
        <p className="text-gray-700 text-justify leading-relaxed">
          The online presence of people and services are grown significantly.
          Astrologer Daddy is taking advantage of these online platforms to
          offer their online astrology service. Astrologer Daddy created a team
          of best and well experienced astrologers specialized in Vastu Shastra,
          Vedic astrology, Tarot card reading, palmistry, Numerology, and many
          more.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          The objective is to provide online astrology service is to save
          valuable time, money, and effort in finding astrologers amidst the
          fast-moving cities. Astrologer Daddy has focused on enhancing customer
          service. We believe that everything cannot be monetized. While
          offering online astrology services, we conduct a host of other
          activities to make people understand the concept of Astrology better.
          From free sessions of astrology predictions to live events conducted
          with Aartis and Pujas at famous temples across India, this is our way
          of reaching out to people.
        </p>
      </section>

      {/* Online Astrologer */}
      <section className="mb-8">
        <h3 className="text-xl font-semibold text-black mb-4">Online Astrologer</h3>
        <p className="text-gray-700 text-justify leading-relaxed">
          A large part of our success is due to the amazing team of online
          astrologers we have. Our astrologers come from various backgrounds,
          and only those with extensive knowledge in their field join our
          platform. They get rated according to the service provided by them to
          the customers, and better ratings reflect better service. You can
          avail astrology services in two ways: either by talking to an
          astrologer over a call or chatting with the astrologer at your
          convenience.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Our astrologers deeply understand this 5000-year-old science of
          astrology and strive to uphold its integrity. They share their
          knowledge not only by consultations but also by providing videos,
          articles, and many other rich contents on our social media pages and
          blog. These include resources about Muhurats, upcoming festivals, and
          online astrology predictions that will satiate your quest for
          astrology knowledge.
        </p>
      </section>

      {/*Reasons to Choose Astrology Experts */}
      <section className="mb-8">
        <h3 className="text-xl font-semibold text-black mb-4">
          Reasons to Choose Astrology Experts
        </h3>
        <p className="text-gray-700 text-justify leading-relaxed">
          Our experts and Astrologer Daddy will be your right choice and best
          guide in matters of astrology. Whether your question s are related to
          horoscopes, numerology, or tarot card readings, career, future,
          relationships, marriage, and love etc. Our experts are ready to all
          the answers of your queries.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          There's a free online astrology session waiting for you. Once you have
          completed your free astrology prediction session, if you would like to
          stay connected with us you can recharge your wallet and get connected
          to live astrologers for instant consultations.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          The best way to choose the right astrologer is to select a category
          and see top rated astrologers based on ratings given by users and you
          can be assured of high quality service. In case of any problems during
          your online astrology consultation, our customer service people are
          always available to help you.
        </p>
        <p className="text-gray-700 text-justify leading-relaxed mt-2">
          Astrology provides a unique way to explore the connections between the
          stars and our lives. Whether you are a believer or a skeptical soul,
          there's no harm in seeing what the stars might say about you. Get your
          birth chart today. You might just be surprised at something new about
          yourself. And for personalized guidance, Astrologer Daddy is always
          ready to help. Astrologer Daddy is an online astrology consultation,
          chat with astrologers, free kundali check, matchmaking, and all daily
          horoscopes in one place.
        </p>
      </section>
    </div>
  );
}

export default AboutSection;
