import React from 'react'

function SectionHeading({title}) {
  return (
    <p className="my-1 lg:my-6 mx-auto text-center text-xl md:text-2xl lg:text-4xl font-semibold">
      {title}
    </p>
  );
}

export default SectionHeading