import React from "react";

const RefundPolicy = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
        Refund Policy
      </h1>

      <p className="mb-6 text-gray-700">
        At BK Astro Service Private Limited, we are committed to providing
        high-quality astrological services to our clients. However, we
        understand that there may be instances where you are not fully satisfied
        with our services. This Refund Policy outlines the conditions under
        which refunds may be issued and the process for requesting a refund.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        1. Eligibility for Refunds
      </h2>
      <p className="mb-6 text-gray-700">
        Refunds are considered in the following circumstances:
      </p>
      <div className="mb-6 text-gray-700 pl-6">
        <ul className="list-disc">
          <li className="mt-2">
            <strong>Service Delivery Issues:</strong> If the service you booked
            was not delivered as promised or there was a technical issue that
            prevented the completion of the service.
          </li>
          <li className="mt-2">
            <strong>Incorrect Charges:</strong> If you were charged incorrectly
            or multiple times for the same service due to a technical error.
          </li>
          <li className="mt-2">
            <strong>Expert Availability:</strong> If the astrologer, Tarot
            reader, or other service expert you booked was unavailable at the
            scheduled time, and a rescheduled session cannot be arranged.
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        2. Non-Refundable Circumstances
      </h2>
      <p className="mb-6 text-gray-700">
        Refunds will not be issued in the following situations:
      </p>
      <div className="mb-6 text-gray-700 pl-6">
        <ul className="list-disc">
          <li className="mt-2">
            <strong>Change of Mind:</strong> If you change your mind after
            booking a session or after the service has been delivered.
          </li>
          <li className="mt-2">
            <strong>Partial Use:</strong> If you have used part of the service
            or session time and then decide to cancel the remainder.
          </li>
          <li className="mt-2">
            <strong>Inaccurate Predictions:</strong> Astrology is a guiding tool
            based on the expertise and interpretation of the astrologer. Refunds
            will not be granted for predictions that do not meet your
            expectations or desired outcomes.
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        3. Refund Request Process
      </h2>
      <p className="mb-6 text-gray-700">
        To request a refund, please follow these steps:
      </p>
      <div className="mb-6 text-gray-700 pl-6">
        <ul className="list-disc">
          <li className="mt-2">
            <strong>Contact Support:</strong> Email our customer support team at
            <a
              href="mailto:support@astrologerdaddy.com"
              className="text-blue-600 hover:underline"
            >
              {" "}
              support@astrologerdaddy.com
            </a>{" "}
            within 24 hours of the issue arising. Include your booking details,
            the reason for the refund request, and any supporting evidence (such
            as screenshots or error messages).
          </li>
          <li className="mt-2">
            <strong>Review Process:</strong> Our support team will review your
            request and respond within 3-5 business days. During this time, we
            may ask for additional information to assess your claim.
          </li>
          <li className="mt-2">
            <strong>Refund Approval:</strong> If your refund request is
            approved, the refund will be processed and credited back to your
            original payment method within 7-10 business days.
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        4. Cancellations
      </h2>
      <div className="mb-6 text-gray-700 pl-6">
        <ul className="list-disc">
          <li className="mt-2">
            <strong>Session Cancellation:</strong> If you wish to cancel a
            scheduled session, please do so at least 24 hours in advance by
            contacting our support team. Cancellations made within this
            timeframe may be eligible for a refund or rescheduling.
          </li>
          <li className="mt-2">
            <strong>Last-Minute Cancellations:</strong> Cancellations made less
            than 24 hours before the session will not be eligible for a refund,
            except in cases of expert unavailability.
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        5. Disputes and Chargebacks
      </h2>
      <p className="mb-6 text-gray-700">
        In case of a dispute or chargeback, we encourage you to contact us first
        to resolve the issue amicably. Filing a chargeback without contacting
        our support team may result in additional processing time and
        complications in resolving the matter.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        6. Amendments to the Refund Policy
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited reserves the right to amend or update
        this Refund Policy at any time without prior notice. Any changes will be
        posted on our website, and it is your responsibility to review the
        policy periodically.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Us</h2>
      <p className="mb-6 text-gray-700">
        If you have any questions or concerns about our Refund Policy, please
        reach out to us at{" "}
        <a
          href="mailto:support@astrologerdaddy.com"
          className="text-blue-600 hover:underline"
        >
          support@astrologerdaddy.com
        </a>
        . We are here to assist you and ensure you have the best experience with
        our services.
      </p>
      <p className="text-base md:text-lg text-gray-700">
        This policy is designed to provide clarity on the refund process and to
        ensure fair treatment for all our clients. Your satisfaction is
        important to us, and we strive to resolve any issues promptly and
        effectively.
      </p>
    </div>
  );
};

export default RefundPolicy;
