// hooks/useValidation.js
const useValidation = (profile) => {
  const validateProfile = () => {
    let errors = {};

    // Name
    if (!profile.name || profile.name.trim() === "") {
      errors.name = "Name is required.";
    }

    // Email
    if (!profile.email || profile.email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profile.email)) {
      errors.email = "Invalid email address.";
    }

    // Gender
    if (!profile.gender || profile.gender.trim() === "") {
      errors.gender = "Gender is required.";
    }

    // Phone Number
    if (!profile.phoneNumber || profile.phoneNumber.trim() === "") {
      errors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{10}$/.test(profile.phoneNumber)) {
      errors.phoneNumber = "Phone number must be a 10-digit number.";
    }

    // Profile Image
    // Check if a profile image is selected (if applicable to your form logic)
    // This might be checked during form submission rather than validation
    if (!profile.profileImage) {
      errors.profileImage = "Profile image is required.";
    }

     // Date of Birth
    if (!profile.dob) {
      errors.dob = "Date of Birth is required.";
    } else {
      const dob = new Date(profile.dob);
      const today = new Date();
      const age = today.getFullYear() - dob.getFullYear();
      if (dob > today) {
        errors.dob = "Date of Birth cannot be in the future.";
      } else if (age < 18) {
        errors.dob = "You must be at least 18 years old.";
      }
    }

    // Experience
    if (
      !profile.experience ||
      isNaN(profile.experience) ||
      profile.experience < 0
    ) {
      errors.experience = "Experience must be a positive number.";
    } else if (profile.experience > 50) {
      errors.experience = "Experience cannot exceed 50 years.";
    }

    // Languages
    if (!profile.languages || profile.languages.length === 0) {
      errors.languages = "At least one language must be selected.";
    }

    // Specializations
    if (!profile.specializations || profile.specializations.length === 0) {
      errors.specializations = "At least one specialization must be selected.";
    }

    // About Me
    if (!profile.aboutMe || profile.aboutMe.trim() === "") {
      errors.aboutMe = "About Me section cannot be empty.";
    }

    return errors;
  };

  return { validateProfile };
};

export default useValidation;
