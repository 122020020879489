import React, { useState, useEffect } from "react";
import { useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import Swal from "sweetalert2";
import api from "../api";
import { useAuth } from "../contexts/AuthContext";

export const MyDescriptionPanel = () => {
  const { token } = useAuth();
  const { useCallCustomData, useCallSession, useCallSettings, useCallEndedBy } =
    useCallStateHooks();
  const custom = useCallCustomData();
  const call = useCall();
  const callSession = useCallSession();
  const callSettings = useCallSettings();
  const callEndedBy = useCallEndedBy();

  const [remainingTime, setRemainingTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    if (callSession) {
      const startTime = new Date(callSession.started_at);
      const endTime = new Date(callSession.timer_ends_at);

      const updateTimes = () => {
        const now = new Date();
        const timeElapsed = now - startTime;
        const timeRemaining = endTime - now;

        setElapsedTime(Math.max(0, timeElapsed));
        setRemainingTime(Math.max(0, timeRemaining));

        // Show alert when 1 minute is remaining, if not already shown
        if (timeRemaining <= 60000 && timeRemaining > 0 && !alertShown) {
          Swal.fire({
            title: "One minute remaining!",
            icon: "warning",
            confirmButtonText: "OK",
          });

          setAlertShown(true);
        }

        // Show alert when the remaining time is zero (call ended)
        if (timeRemaining <= 0 || callEndedBy) {
          Swal.fire({
            title: "Call has ended",
            text: "Thank you for your time.",
            icon: "info",
            confirmButtonText: "OK",
            footer: callEndedBy?.name
              ? `Call Ended By: ${callEndedBy?.name}`
              : "Call Time Expired",
            confirmButtonColor: "#7f1d1d",
          }).then(async () => {
            const formatTime = (time) => {
              const minutes = Math.floor(time / 60000);
              const seconds = Math.floor((time % 60000) / 1000);
              return `${minutes}:${seconds.toString().padStart(2, "0")}`;
            };

            const formattedDuration = formatTime(timeElapsed);

            await api.get(
              `/appointment/update-status/${call.id}?duration=${formattedDuration}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            window.location.href = "/";
          });

          clearInterval(intervalId); // Stop the interval when the call ends
        }
      };

      const intervalId = setInterval(updateTimes, 1000);

      return () => clearInterval(intervalId);
    }
  }, [callSession, alertShown, call, callSettings, token, callEndedBy]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="text-center mb-4">
      <h2 className="text-2xl font-bold text-white">{custom?.title}</h2>
      <p className="text-sm text-gray-100">{custom?.description}</p>
      {remainingTime !== null && elapsedTime !== null && (
        <div className="mt-2 text-white font-medium">
          <p>
            Time {formatTime(elapsedTime)} out of{" "}
            {formatTime(elapsedTime + remainingTime)}
          </p>
          <p>Remaining: {formatTime(remainingTime)}</p>
        </div>
      )}
    </div>
  );
};
