// Custom styles for react-select
const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    borderColor: "#d1d5db", // Light gray border color
    boxShadow: "none",
    "&:hover": {
      borderColor: "#facc15", // Yellow border on hover
    },
    borderRadius: "0.375rem", // Rounded corners
    padding: "0.5rem", // Padding inside the control
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.375rem", // Rounded corners
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Subtle shadow
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#facc15" : provided.backgroundColor,
    color: state.isSelected ? "#000" : provided.color,
    "&:hover": {
      backgroundColor: "#fef3c7", // Lighter yellow on hover
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#facc15", // Yellow background for selected items
    color: "#000",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#000",
    "&:hover": {
      backgroundColor: "#fef3c7", // Lighter yellow on hover
      color: "#000",
    },
  }),
};
export default customStylesSelect;