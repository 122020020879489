import React from 'react'

function ViewMoreButton({title,click}) {
  return (
    <div className="w-full flex justify-center items-center mt-4">
      <button onClick={click} className="mx-auto w-fit rounded px-4 py-2 font-medium bg-red-600 hover:bg-red-500 text-white text-center">
        {title}
      </button>
    </div>
  );
}

export default ViewMoreButton