import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import api from "../api";
import useSpecializations from "../hooks/useSpecializations";
import useValidation from "../hooks/useValidation";

function RegisterAstrologer() {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    email: "",
    phoneNumber: "",
    profileImage: null,
    dob: "",
    specializations: [],
    experience: "",
    languages: [],
    aboutMe: "",
  });

  const { specializations } = useSpecializations();

  const { validateProfile } = useValidation(formData);

  const [otp, setOtp] = useState("");
  const [orderId, setOrderId] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) error = "Name is required";
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) error = "Invalid email address";
        break;
      case "phoneNumber":
        const phonePattern = /^[6-9]\d{9}$/;
        if (!phonePattern.test(value)) error = "Invalid phone number";
        break;
      case "profileImage":
        if (!value) error = "Profile image is required";
        break;
      case "dob":
        if (!value) error = "Date of birth is required";
        break;
      case "experience":
        if (value <= 0) error = "Experience must be greater than 0";
        break;

      case "aboutMe":
        if (!value) error = "About Me is required";
        break;
      default:
        break;
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2MB

    let error = "";

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        error = "Invalid file type. Only JPEG, PNG, and GIF are allowed.";
      } else if (file.size > maxSize) {
        error = "File size exceeds 2MB.";
      }
    } else {
      error = "Profile image is required";
    }

    setFormData({ ...formData, profileImage: file });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      profileImage: error,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevData) => {
      if (checked) {
        return { ...prevData, [name]: [...prevData[name], value] };
      } else {
        return {
          ...prevData,
          [name]: prevData[name].filter((item) => item !== value),
        };
      }
    });
  };

  const handleSpecializationChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      if (checked) {
        return {
          ...prevData,
          specializations: [...prevData.specializations, value],
        };
      } else {
        return {
          ...prevData,
          specializations: prevData.specializations.filter(
            (spec) => spec !== value
          ),
        };
      }
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submission
    const validationErrors = validateProfile();
    setValidationErrors(validationErrors);


    

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    
    setIsLoading(true);

    try {
      const response = await api.post(
        "/astrologers/register",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setOtpSent(true);
      setError("");
      setSuccess("OTP sent to your phone");
      setOrderId(response.data.data.orderId);
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.response.message) {
        setError(err.response.message);
        return;
      }
      setError("Failed to send OTP. Please try again.");
      setSuccess("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.post(
        "/astrologers/verify-otp",
        { phoneNumber: formData.phoneNumber, otp, orderId }
      );
      setSuccess("Registration successful!");
      setError("");
      setTimeout(() => {
        navigate("/login")
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      setError(
        err?.response?.data?.message ||
          "Oops we are having some issue. Please try again later."
      );
      if (err?.response?.data?.reason === "Expired") {
        setSuccess("");
        setError("OTP Expired");
        
      }
      setIsLoading(false);
    } 
  };

  return (
    <div className="flex items-center justify-center min-h-[82vh] bg-gradient-to-r from-orange-400 to-yellow-400 px-4">
      <div className="p-8 bg-white shadow-lg rounded-lg max-w-5xl w-full my-4 md:my-8 xl:my-16">
        <div className="flex justify-center mb-4">
          <div className="mb-4 text-center">
            <h1 className="text-gray-700 text-3xl font-bold mb-4">
              Astrologer Portal
            </h1>
          </div>
        </div>
        {otpSent ? (
          <form onSubmit={handleOtpSubmit}>
            <h2 className="text-2xl font-bold mb-4">Enter OTP</h2>
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {success && <div className="mb-4 text-green-500">{success}</div>}
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">OTP</label>
              <input
                type="text"
                name="otp"
                className="w-full p-2 border border-gray-300 rounded"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition duration-200"
              disabled={isLoading}
            >
              {isLoading ? "Verifying OTP..." : "Verify OTP"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleFormSubmit} encType="multipart/form-data">
            <h2 className="text-2xl text-gray-700 font-bold mb-4">
              Register as Astrologer
            </h2>
            <h2 className="text-xl text-gray-700 font-semibold mb-6">
              Please fill out the form below to register as an astrologer. After
              submission, your profile will be reviewed, and you will need to
              wait for approval before your registration is complete.
            </h2>
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {success && <div className="mb-4 text-green-500">{success}</div>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {validationErrors.name && (
                  <div className="text-red-500">{validationErrors.name}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Gender
                </label>
                <select
                  name="gender"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {validationErrors.email && (
                  <div className="text-red-500">{validationErrors.email}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Phone Number
                </label>
                <div className="flex items-center border border-gray-300 p-2 rounded">
                  <span className="text-gray-500 mr-2">+91</span>
                  <FaPhone className="text-gray-500 mr-2" />
                  <input
                    type="text"
                    name="phoneNumber"
                    className="flex-1 focus:outline-none"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                {validationErrors.phoneNumber && (
                  <div className="text-red-500">
                    {validationErrors.phoneNumber}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Profile Image
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="w-full p-2 border border-gray-300 rounded"
                  onChange={handleFileChange}
                  required
                />
                {validationErrors.profileImage && (
                  <div className="text-red-500">
                    {validationErrors.profileImage}
                  </div>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.dob}
                  onChange={handleChange}
                />
                {validationErrors.dob && (
                  <div className="text-red-500">{validationErrors.dob}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-1">
                  Specializations
                </label>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                  {specializations.map((spec) => (
                    <div key={spec._id}>
                      <label>
                        <input
                          type="checkbox"
                          name="specializations"
                          value={spec._id}
                          checked={formData.specializations.includes(spec._id)}
                          onChange={handleSpecializationChange}
                        />{" "}
                        {spec.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  Experience (in years)
                </label>
                <input
                  type="number"
                  name="experience"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.experience}
                  onChange={handleChange}
                  required
                />
                {validationErrors.experience && (
                  <div className="text-red-500">
                    {validationErrors.experience}
                  </div>
                )}
              </div>

              <div className="mb-4 w-full">
                <label className="block text-gray-700 font-semibold">
                  Languages
                </label>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                  {[
                    "Hindi",
                    "English",
                    "Bengali",
                    "Telugu",
                    "Marathi",
                    "Tamil",
                    "Gujarati",
                    "Kannada",
                    "Odia",
                    "Punjabi",
                    "Malayalam",
                    "Assamese",
                  ].map((lang) => (
                    <div key={lang}>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          name="languages"
                          value={lang}
                          checked={formData.languages.includes(lang)}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        {lang}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">
                  About Me
                </label>
                <textarea
                  name="aboutMe"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={formData.aboutMe}
                  onChange={handleChange}
                  required
                />
                {validationErrors.aboutMe && (
                  <div className="text-red-500">{validationErrors.aboutMe}</div>
                )}
              </div>
            </div>
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {success && <div className="mb-4 text-green-500">{success}</div>}
            <button
              type="submit"
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition duration-200"
              disabled={isLoading}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default RegisterAstrologer;
