import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import astro from "../assets/astro_img.png"
import AstrologerCard from "../components/AstrologerCard";

import {
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
  FaChevronLeft,
  FaChevronRight,
  FaPhoneAlt,
  FaComments,
  FaCommentDots,
} from "react-icons/fa";

import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Spinner from "../components/Spinner";

function Astrologer() {
  const navigate = useNavigate();
  const params = useParams();

  const [astrologer, setAstrologer] = useState({});
  const [suggestedAstrologers, setSuggestedAstrologers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fallbackReviews = [
    {
      user: { firstName: "Rohit", lastName: "Sharma" },
      rating: 4.5,
      comment: "Very insightful and accurate predictions. Highly recommended!",
    },
    {
      user: { firstName: "Sanya", lastName: "Singh" },
      rating: 4.0,
      comment: "Had a great session, learned a lot about myself and my future.",
    },
    {
      user: { firstName: "Anil", lastName: "Kumar" },
      rating: 5.0,
      comment: "Excellent astrologer! The reading was spot on.",
    },
  ];

  useEffect(() => {
    const fetchAstrologer = async () => {
      try {
        const response = await api.get(`/astrologers/${params.astrologer_id}`);
        const data = await response.data;
        setAstrologer(data);
        // Fetch suggested astrologers based on current astrologer's specializations
        const specializations = response.data.specializations
          .map((sp) => sp._id)
          .join(",");
        const suggestionsResponse = await api.get(`/astrologers`, {
          params: { specializations, suggestions: true },
        });
        setSuggestedAstrologers(suggestionsResponse.data.astrologers);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchAstrologer();
  }, [params.astrologer_id]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-yellow-500 inline" />);
      } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
        stars.push(
          <FaStarHalfAlt key={i} className="text-yellow-500 inline" />
        );
      } else {
        stars.push(<FaRegStar key={i} className="text-yellow-500 inline" />);
      }
    }
    return stars;
  };

  const imageSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dummyImages = [
    "https://via.placeholder.com/300x200?text=Image+1",
    "https://via.placeholder.com/300x200?text=Image+2",
    "https://via.placeholder.com/300x200?text=Image+3",
    "https://via.placeholder.com/300x200?text=Image+4",
    "https://via.placeholder.com/300x200?text=Image+5",
    "https://via.placeholder.com/300x200?text=Image+6",
    "https://via.placeholder.com/300x200?text=Image+7",
    "https://via.placeholder.com/300x200?text=Image+8",
    "https://via.placeholder.com/300x200?text=Image+9",
    "https://via.placeholder.com/300x200?text=Image+10",
  ];
;

  // Custom Arrow Components
  const CustomPrevArrowLeft = ({ onClick }) => (
    <button
      className="absolute -left-10 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300 z-10"
      onClick={onClick}
    >
      <FaChevronLeft />
    </button>
  );

  const CustomNextArrowRight = ({ onClick }) => (
    <button
      className="absolute -right-10 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2 shadow hover:bg-gray-300 z-10"
      onClick={onClick}
    >
      <FaChevronRight />
    </button>
  );

  const suggestedSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    prevArrow: <CustomPrevArrowLeft />,
    nextArrow: <CustomNextArrowRight />,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const calculateRatingData = (reviews) => {
    const ratingCounts = [0, 0, 0, 0, 0]; // Array to hold the count of each star rating

    reviews.forEach((review) => {
      if (review.rating) {
        const starRating = Math.floor(review.rating);
        ratingCounts[starRating - 1] += 1;
      }
    });

    const totalReviews = reviews.length;
    return ratingCounts.map((count, index) => ({
      stars: index + 1,
      value: (count / totalReviews) * 100, // percentage
    }));
  };

   if (loading) {
     return (
       <div className="flex justify-center my-20">
         <Spinner className="w-16 h-16" />
       </div>
     );
   }

  const ratingData = calculateRatingData(astrologer.reviews || []);

  return (
    <div className="min-h-screen p-4 bg-yellow-500">
      <div
        className="relative max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "",
          backgroundImage: `url(${astro})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0   rounded-lg"></div>

        <div className="relative z-10 flex flex-col lg:mx-7 md:flex-row gap-4">
          {/* Name, Specialization, and Language */}
          <div className="flex lg:items-start items-center justify-center flex-col md:w-1/3 text-black text-3xl">
            <h2 className="text-3xl lg:text-5xl font-bold mb-2">
              {astrologer.name}
            </h2>
            <p className="text-base lg:text-lg mb-1 my-2">
              <span className="bg-blue-100 text-blue-800 font-medium px-2.5 py-0.5 rounded">
                {astrologer.specializations.map((spec) => spec.name).join(", ")}
              </span>
            </p>
            <p className="text-base lg:text-lg mb-1 my-2">
              <span className="bg-purple-100 text-purple-800 font-medium px-2.5 py-0.5 rounded">
                {astrologer.languages.join(", ")}
              </span>
            </p>
          </div>

          {/* Astrologer Image */}

          <div className="flex-shrink-0 flex items-center justify-center md:w-1/3">
            <img
              src={`https://astrologerdaddy.com/api/${astrologer.profileImage}`}
              alt="Astrologer Avatar"
              className="border-4 border-white shadow-lg object-cover object-center rounded-full"
              style={{ width: "250px", height: "250px" }}
            />
          </div>

          {/* Call and Chat */}
          <div className="flex lg:items-center flex-col mt-4 md:w-1/3 lg:mt-20 gap-4">
            <div className="flex gap-4 flex-wrap lg:items-center  justify-center">
              <button className="flex items-center px-4 py-2 border-2 text-white bg-red-600 font-semibold rounded-lg hover:bg-red-500 focus:outline-none">
                <FaPhoneAlt className="mr-2" />
                Call
              </button>
              <button className="flex items-center px-4 py-2 border-2 text-white bg-red-600 font-semibold rounded-lg hover:bg-red-500 focus:outline-none">
                <FaComments className="mr-2" />
                Chat
              </button>
            </div>

            <div className="flex gap-4 flex-wrap justify-center text-black">
              <p className="flex items-center font-semibold">
                ₹ {astrologer.minutePrice}/minute
              </p>
              <p className="flex items-center font-semibold">
                Minimum : {astrologer.minimumCallDuration} minutes per call/chat
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Image Slider Section */}
      <div className="max-w-7xl mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg">
        <Slider {...imageSliderSettings}>
          {dummyImages.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="max-w-7xl mx-auto mt-6 p-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">About Me</h3>
        <p>{astrologer.aboutMe}</p>
      </div>
      <div className="max-w-7xl mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4 flex items-center">
          Ratings and Reviews
        </h3>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-1/3 bg-gray-100 p-4 mb-8 md:mb-0 rounded-lg shadow">
            {ratingData.map((item, index) => (
              <div key={index} className="mb-4">
                <div className="flex items-center">
                  <div className="w-1/4 text-right pr-2">
                    {item.stars} Stars
                  </div>
                  <div className="w-3/4">
                    <div className="bg-gray-300 h-3 rounded-full overflow-hidden">
                      <div
                        className="bg-yellow-500 h-full"
                        style={{ width: `${item.value}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-6">
              <p className="text-lg font-semibold flex items-center">
                <FaCommentDots className="text-yellow-500 mr-2" />
                Total Reviews:{" "}
                {astrologer.reviews ? astrologer.reviews.length : 0}
              </p>
            </div>
          </div>
          <div className="md:w-2/3 relative mb-8 h-96 overflow-y-auto">
            {(astrologer.reviews && astrologer.reviews.length > 0
              ? astrologer.reviews
              : fallbackReviews
            ).map((review, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow mb-4"
              >
                <p className="text-lg font-bold capitalize">
                  {review.user.firstName + " " + review.user.lastName}
                </p>
                <p className="text-yellow-500 mb-2">
                  {renderStars(review.rating)}
                </p>
                <p>{review.comment}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-7xl mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Suggested Astrologers</h3>
          <Slider {...suggestedSliderSettings}>
            {suggestedAstrologers.map((astro) => (
              <div key={astro._id} className="px-2">
                <AstrologerCard
                  avatar={astro.profileImage}
                  name={astro.name}
                  id={astro._id}
                  ratings={astro.ratings}
                  experience={astro.experience}
                  category={astro.category}
                  languages={astro.languages}
                  specializations={astro.specializations}
                  minutePrice={astro.minutePrice}
                  minimumCallDuration={astro.minimumCallDuration}
                  showCallChat={false}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Astrologer;
