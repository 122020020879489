import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-red-900 text-white py-10">
      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 px-4">
        <div>
          <h3 className="text-lg font-semibold mb-2">Get Advice On</h3>
          <ul className="space-y-1">
            <li>Finance & Business</li>
            <li>Psychic Reading</li>
            <li>Break-Up & Divorce</li>
            <li>Vedic Astrology</li>
            <li>Kids & Education</li>
            <li>Tarot Reading</li>
            <li>Marital Life</li>
            <li>Love & Relationships</li>
            <li>Career & Job</li>
            <li>Cheating & Affairs</li>
            <li>Numerology</li>
            <li>Horary Astrology</li>
            <li>Relationship Counseling</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Astrology Tools</h3>
          <ul className="space-y-1">
            <li>Muhurat</li>
            <li>Choghadiya</li>
            <li>Rahu Kaal</li>
            <li>Shubha Hora</li>
            <li>Gowri Panchangam</li>
            <li>Tarot</li>
            <li>Panchang</li>
            <li>Today's Panchang</li>
            <li>Astrology</li>
            <li>Kundali Matching</li>
            <li>Free Janam Kundali</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Vrat and Upvaas</h3>
          <ul className="space-y-1">
            <li>Purnima Vrat</li>
            <li>Amavasya Dates</li>
            <li>Ekadashi Vrat</li>
            <li>Pradosh Vrat</li>
            <li>Sankashti Chaturthi</li>
            <li>Vinayaka Chaturthi</li>
            <li>Sankranti Dates</li>
            <li>Satyanarayan Puja</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Horoscope</h3>
          <ul className="space-y-1">
            <li>Daily Horoscope</li>
            <li>Monthly Horoscope</li>
            <li>Yearly Horoscope</li>
          </ul>
          <h3 className="text-lg font-semibold my-2">Astrologer section</h3>
          <ul className="space-y-1">
            <li className="hover:text-gray-200 cursor-pointer">
              <a href="http://astrologer.astrologerdaddy.com/login">
                Astrologer Login
              </a>
            </li>
            <li className="hover:text-gray-200 cursor-pointer">
              <a href="http://astrologer.astrologerdaddy.com/register">
                Registration Astrologer
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Useful Links</h3>
          <ul className="space-y-1">
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>Contact Us</li>
            <li>Blog</li>
            <li>FAQs</li>
            <li>Support</li>
            <li>Online Puja</li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Policy</h3>
          <ul className="space-y-1">
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/pricing-policy">Pricing Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms Of Use</Link>
            </li>
            <li>
              <Link to="/refund-policy">Refund & Cancellation</Link>
            </li>
            <li>
              <Link to="/disclaimer">Disclaimer</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto mt-10 px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center border-t border-gray-700 pt-4">
          <div className="flex space-x-4 mb-4 sm:mb-0">
            <p className="text-white">Download Our Apps</p>
            <a href="#" className="text-gray-400 hover:text-white">
              Google Play
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              App Store
            </a>
          </div>
          <div className="flex space-x-4 flex-wrap">
            <a href="#" className="text-gray-400 hover:text-white">
              facebook
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              twitter
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              linkedin
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              instagram
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              youtube
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              pinterest
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
