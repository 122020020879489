const TOP_INDIAN_LANGUAGES = [
  "Hindi",
  "English",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Punjabi",
  "Malayalam",
  "Assamese",
];

export default TOP_INDIAN_LANGUAGES;
