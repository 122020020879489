import React, { useEffect, useState } from "react";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Swal from "sweetalert2";
import { useAuth } from "../contexts/AuthContext";

function ChatPage() {
  const { ws, sendMessage, messages, setMessages, chatEnded, setChatEnded } =
    useWebSocket();
  const [newMessage, setNewMessage] = useState("");
  const [appointment, setAppointment] = useState({});

  const params = useParams();
  const navigate = useNavigate();
  const appointmentId = params.chat_id;

  const { token } = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const response = await api.get(
          `/appointment/get-appointment/single/${appointmentId}?chats=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const appointment = response.data.appointment;

        setAppointment(appointment);

        if (appointment.status === "Completed") {
          setChatEnded(true);
          Swal.fire({
            icon: "warning",
            title: "Appointment Completed",
            text: "This appointment has already been completed.",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/astrologer/appointments");
          });
        } else {
          // Reset messages and chat status when starting a new chat
          setMessages([]);
          setChatEnded(false);
        }
        const messages = response.data.messages.map((message) => {
          return {
            sender: message.onModel.toLowerCase(),
            text: message.text,
            time: message.createdAt,
          };
        });

        setMessages(messages);
      } catch (error) {
        console.error("Error fetching appointment:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while fetching the appointment details.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/astrologer/appointments");
        });
      }
    };

    fetchAppointment();
  }, [appointmentId, navigate]);

  useEffect(() => {
    if (ws) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received WebSocket message:", data);

        if (data.type === "CHAT_MESSAGE") {
          setMessages((prevMessages) => [...prevMessages, data.message]);
        } else if (
          data.type === "ERROR" &&
          data.message === "Chat session has ended."
        ) {
          setChatEnded(true);
        } else if (data.type === "END_CHAT") {
          setChatEnded(true);
        }
      };

      ws.onmessage = handleMessage;

      return () => {
        ws.onmessage = null; // Clean up the WebSocket event listener
      };
    }
  }, [ws, setMessages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" || chatEnded) return;

    const message = {
      type: "CHAT_MESSAGE",
      content: newMessage,
      appointmentId,
    };

    sendMessage(message);

    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: newMessage,
        sender: "astrologer",
        time,
      },
    ]);

    setNewMessage("");
  };

  const endChat = () => {
    const message = {
      type: "END_CHAT",
      appointmentId,
    };

    sendMessage(message);
    setChatEnded(true);
  };

  return (
    <div className="flex flex-col min-h-[84vh] lg:max-w-[1380px] lg:m-auto bg-gray-100 my-10">
      {/* Chat section */}
      <div className="flex-1 p-4 overflow-auto min-h-0">
        <div className="space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.sender === "astrologer" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`max-w-xs p-3 rounded-lg relative ${
                  message.sender === "astrologer"
                    ? "bg-yellow-400 text-white"
                    : "bg-gray-200 text-gray-900"
                }`}
                style={{ minHeight: "60px" }}
              >
                <div className="flex flex-col">
                  <div className="text-sm font-bold mb-1 capitalize">
                    {message.sender === "astrologer" ? "You" : `${appointment.user.firstName} ${appointment.user.lastName}`}
                  </div>
                  <div className="flex justify-between items-end gap-2">
                    <div className="whitespace-pre-wrap">{message.text}</div>
                    <div
                      className={`text-xs ${
                        message.sender === "astrologer"
                          ? "text-white"
                          : "text-gray-600"
                      }`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {message.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Input section */}
      {!chatEnded ? (
        <div className="p-4 border-t bg-white sticky bottom-0">
          <div className="flex items-center w-full space-x-2 overflow-hidden">
            <input
              type="text"
              className="min-w-0 flex-grow p-2 border border-yellow-400 rounded-lg outline-none focus:ring-2 focus:ring-yellow-500 ffocus:outline-none text-sm"
              placeholder="Enter your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleSendMessage(e) : null)}
            />
            <button
              onClick={handleSendMessage}
              className="px-2 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 text-sm flex-shrink-0"
            >
              Send
            </button>
            <button
              onClick={endChat}
              className="px-2 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 text-sm flex-shrink-0"
            >
              End Chat
            </button>
          </div>
        </div>
      ) : (
        <div className="p-4 border-t bg-white sticky bottom-0 text-center">
          <p className="text-red-500">Appointment has ended.</p>
        </div>
      )}
    </div>
  );
}

export default ChatPage;
