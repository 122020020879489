import React from "react";
import { MyMicButton } from "./MyMicButton";
import MyCallEndButton from "./MyCallEndButton";

export const MyControlsPanel = () => {
  return (
    <div className="flex justify-center space-x-4">
      <MyMicButton />
      <MyCallEndButton/>
    </div>
  );
};
