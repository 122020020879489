import { StreamCall, StreamVideo } from "@stream-io/video-react-sdk";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import { CallUILayout } from "../components/CallUILayout";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useClient } from "../contexts/ClientContext";

export default function AudioAppointment() {
  const params = useParams();
  const { client } = useClient();
  const callId = params.call_id;
  if (!callId) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Call! or call already ended",
      confirmButtonColor: "#7f1d1d",
    }).then(() => {
      window.location.href = "/";
    });
    return null; // Prevent rendering if data is missing
  }
  let call = null;
  if (client) {
    call = client.call("audio_room", callId);
    async function joinCall() {
      try {
        console.log("calling...");
        const callDetails = await call.get();
        if (callDetails) {
          await call.join();
        }
      } catch (error) {
        console.error("Error joining the call", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "This Call has Ended!",
          confirmButtonColor: "#7f1d1d",
        }).then(() => {
          window.location.href = "/";
        });
      }
    }

    joinCall();
    
    return (
      <StreamVideo client={client}>
        <StreamCall call={call}>
          <CallUILayout />
        </StreamCall>
      </StreamVideo>
    );
  }
  else{
    return <p>loading</p>
  }
}
