import React, { useEffect, useState } from "react";
import api from "../api";

function AdSection() {

  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await api.get("assets");
        const bannersArray = response.data.filter((banner) => banner.type === "ad")
        setBanners(bannersArray);
        console.log(bannersArray);
        
      } catch (error) {
        console.error("Error fetching banners", error);
      }
    };

    fetchBanners();
  }, []);

  const images = [
    { url: "https://via.placeholder.com/300x200?text=Ad+1", link: "#" },
    { url: "https://via.placeholder.com/300x200?text=Ad+2", link: "#" },
  ];

  return (
    <div className="flex flex-wrap justify-between p-4 w-full">
      {banners.map((banner, index) => (
        <a
          href={"#"}
          key={index}
          className="m-2 w-full grow sm:w-1/2 lg:w-1/3"
        >
          <img
            src={`https://astrologerdaddy.com/api/banners/${banner.path}`}
            alt={`Ad ${index + 1}`}
            className="w-full h-auto"
          />
        </a>
      ))}
    </div>
  );
}

export default AdSection;
