import React from "react";
import Slider from "react-slick";

export default function BannerSlider({ banners }) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider {...settings}>
      {banners.map((banner) => (
        <div className="border-0 outline-none" key={banner.path}>
          <img
            src={`https://astrologerdaddy.com/api/banners/${banner.path}`}
            className="w-full max-h-[70vh]"
            alt="1"
          />
        </div>
      ))}
    </Slider>
  );
}
