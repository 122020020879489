import React from "react";

const AboutUs = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">
        About Us
      </h1>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Who We Are</h2>
      <p className="mb-6 text-gray-700">
        Welcome to BK Astro Service Private Limited, your premier destination
        for online astrology predictions. With a vast network of over 13,000
        Vedic astrologers, Tarot readers, Numerologists, Vastu experts, and
        more, we provide unparalleled access to astrological insights. Connect
        with our experts via call or chat and find answers to all your life's
        questions.
        <br />
        <br />
        From Kundli matching to detailed predictions about marriage, love,
        career, health, and beyond, BK Astro Service ensures you receive
        accurate, precise, and well-researched astrological content to alleviate
        your concerns.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        What We Do
      </h2>
      <p className="mb-6 text-gray-700">
        At BK Astro Service, we unite astrologers with extensive knowledge of
        occult sciences on one platform, available 24/7. Besides offering
        top-notch future predictions to help you navigate life's challenges, we
        provide Free Live astrology sessions, Daily horoscopes, Free Kundli
        matching services, a Spiritual store, and much more.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Mission
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        Our mission is to build a community for those seeking astrological
        guidance to improve their lives. We aim to support individuals facing
        difficult times in the most trusted manner.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Vision
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        Our vision is to deliver astrological solutions to those dealing with
        life's challenges, offering direction and support through our trusted
        and certified astrologers.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        We've Got Your Back
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        At BK Astro Service, our astrologers and support team work together as a
        family to ensure you have the best astrology experience. Whether you're
        a teenager deciding on a career or an adult dealing with marriage
        compatibility issues, BK Astro Service is here to provide guidance,
        direction, and happiness.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Our Story
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        BK Astro Service was founded with the goal of preserving the essence of
        traditional astrology while addressing modern issues like mental health,
        stress, and depression. Founder Puneet Gupta has dedicated himself to
        offering the finest Vedic astrology and promoting mental wellness
        through spiritual means. With God's grace, we've achieved significant
        milestones and continue to grow. Today, with over 13,000 astrologers and
        more than 1 lakh minutes of daily consultations, we offer a range of
        services including Free live sessions, Free Kundli matching, Daily
        horoscopes, and more.
        <br />
        <br /> As BK Astro Service expands, so does our commitment to providing
        the best astrology services. We are building the world's largest
        community of spiritual and well-being experts, and we invite you to join
        us on this journey, one prediction at a time.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Why BK Astro Service
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        BK Astro Service is trusted by audiences in over 60 countries and boosts
        a growing network of over 13,000 top Vedic astrologers, Tarot readers,
        Numerologists, and Vastu experts. We ensure that every astrologer on our
        platform is the best, with user ratings of 4 stars and above. This
        commitment to quality has helped us amass over 43 million customers and
        conduct over 1 lakh minutes of daily consultations. We prioritize your
        privacy, allowing you to trust and confidently recommend us.
        <br />
        <br />
        Contact us at{" "}
        <a
          href="mailto:support@astrologerdaddy.com"
          className="text-blue-600 hover:underline"
        >
          support@astrologerdaddy.com
        </a>{" "}
        for more information.
      </p>
    </div>
  );
};

export default AboutUs;
