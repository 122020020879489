import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { ClientProvider } from "./contexts/ClientContext.jsx";
import { ChatProvider } from "./contexts/ChatContext.jsx";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ClientProvider>
      <ChatProvider>
        <WebSocketProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </WebSocketProvider>
      </ChatProvider>
    </ClientProvider>
  </AuthProvider>
);
