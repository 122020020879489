import React from "react";
import { useCallStateHooks } from "@stream-io/video-react-sdk";
import {
  FaMicrophone,
  FaMicrophoneSlash,
} from "react-icons/fa";

export const MyMicButton = () => {
  const { useMicrophoneState } = useCallStateHooks();
  const { microphone, isMute } = useMicrophoneState();

  return (
    <button
      className={`p-4 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        isMute
          ? "bg-red-600 hover:bg-red-700 focus:ring-red-600"
          : "bg-green-600 hover:bg-green-700 focus:ring-green-600"
      }`}
      onClick={async () => {
        if (isMute) {
          await microphone.enable();
        } else {
          await microphone.disable();
        }
      }}
    >
      {isMute ? (
        <FaMicrophoneSlash size="24" className="text-white" />
      ) : (
        <FaMicrophone size="24" className="text-white" />
      )}
    </button>
  );
};
