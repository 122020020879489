import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">
        Terms & Conditions
      </h1>

      <p className="mb-6 text-gray-700">
        Welcome to BK Astro Service Private Limited. By using our services, you
        agree to comply with and be bound by the following terms and conditions.
        Please review these terms carefully before using our website and
        services.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Acceptance of Terms
      </h2>
      <p className="mb-6 text-gray-700">
        By accessing or using our website, you acknowledge that you have read,
        understood, and agree to be bound by these terms and conditions, as well
        as our Privacy Policy. These terms apply to all visitors, users, and
        others who access or use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Services</h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited provides astrological consultation
        services, including but not limited to, Vedic astrology, Tarot reading,
        Numerology, and Vastu Shastra. Our services are available through calls,
        chats, and other digital means. The advice and information provided by
        our experts are based on their knowledge and experience in their
        respective fields.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        User Responsibilities
      </h2>
      <p className="mb-6 text-gray-700 pl-4">
        <strong className="block">1. Registration:</strong>
        To use certain features of our services, you may need to register and
        create an account. You agree to provide accurate and complete
        information during the registration process and to keep your account
        information updated.
      </p>
      <p className="mb-6 text-gray-700 pl-4">
        <strong className="block">2. Account Security:</strong>
        You are responsible for maintaining the confidentiality of your account
        information and for all activities that occur under your account. You
        agree to notify us immediately of any unauthorized use of your account
        or any other security breach.
      </p>
      <p className="mb-6 text-gray-700 pl-4">
        <strong className="block">3. Prohibited Conduct:</strong>
        You agree not to use our services for any unlawful purpose or in any way
        that could harm or disable our website or services. This includes, but
        is not limited to, uploading or transmitting viruses, spamming, or
        engaging in any activity that interferes with or disrupts the
        functionality of our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Us</h2>
      <p className="mb-6 text-gray-700">
        If you have any questions about these terms and conditions, please
        contact us at:
        <br />
        <br />
        BK Astro Service Private Limited
        <br />
        Email:{" "}
        <a
          href="mailto:support@astrologerdaddy.com"
          className="text-blue-600 hover:underline"
        >
          support@astrologerdaddy.com
        </a>
        <br />
        <br />
        Thank you for choosing BK Astro Service Private Limited. We look forward
        to serving you.
      </p>
    </div>
  );
};

export default TermsAndConditions;
