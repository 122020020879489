import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import io from "socket.io-client";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [pendingAppointments, setPendingAppointments] = useState(0);
  const [messages, setMessages] = useState([]);
  const [chatEnded, setChatEnded] = useState(false);

  const initializeSocket = useCallback(
    (astrologerId) => {
      // const socket = io("http://localhost:5000/", {
      //   query: { "astrologer-id": astrologerId },
      // });
      const socket = io("https://astrologerdaddy.com/", {
        query: { "astrologer-id": astrologerId },
      });

      socket.on("connect", () => {
        console.log("Socket.io connection established.");
      });

      socket.on("message", (data) => {
        console.log("Received Socket.io message:", data);

        if (data.type === "CHAT_MESSAGE") {
          setMessages((prevMessages) => [...prevMessages, data.message]);
        } else if (data.type === "NEW_APPOINTMENT") {
          setPendingAppointments((prevCount) => prevCount + 1);
          console.log("Pending appointments:", pendingAppointments);
        } else if (
          data.type === "END_CHAT" ||
          (data.type === "ERROR" && data.message === "Chat session has ended.")
        ) {
          setChatEnded(true);
        }
      });

      socket.on("disconnect", () => {
        console.log("Socket.io connection closed.");
      });

      setSocket(socket);

      return () => {
        socket.disconnect();
      };
    },
    [pendingAppointments]
  );

  useEffect(() => {
    const astrologer = JSON.parse(localStorage.getItem("user"));
    if (astrologer && astrologer._id) {
      const cleanupSocket = initializeSocket(astrologer._id);

      return cleanupSocket;
    }
  }, [initializeSocket]);

  const sendMessage = (message) => {
    if (socket) {
      console.log("Sending message:", message);
      socket.send(JSON.stringify(message));
    } else {
      console.error("Socket is not connected.");
    }
  };

  const resetPendingAppointments = () => {
    setPendingAppointments(0);
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        sendMessage,
        messages,
        setMessages,
        chatEnded,
        setChatEnded,
        pendingAppointments,
        resetPendingAppointments, // Provide resetPendingAppointments in context
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
