import React, { useEffect, useState } from "react";
import BannerSlider from "../components/BannerSlider";
import api from "../api";

function BannerSection() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await api.get("assets");
        const bannersArray = response.data.filter((banner)=>banner.type==="banner")
        setBanners(bannersArray);
      } catch (error) {
        console.error("Error fetching banners", error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="mx-auto">
      <BannerSlider banners={banners} />
    </div>
  );
}

export default React.memo(BannerSection);
