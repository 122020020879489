import React, { useState, useEffect } from "react";
import {
  FaCalendarAlt,
  FaPhone,
  FaStar,
  FaLanguage,
  FaEdit,
  FaSave,
  FaUser,
  FaTransgender,
  FaEnvelope,
  FaBriefcase,
  FaShieldAlt,
  FaCalendarCheck,
  FaClock,
  FaRupeeSign,
  FaWallet,
  FaUserCircle,
  FaImage,
  FaVideo,
  FaTrash,
} from "react-icons/fa";
import api from "../api";
import { useAuth } from "../contexts/AuthContext";
import useSpecializations from "../hooks/useSpecializations";
import useValidation from "../hooks/useValidation";

const TOP_INDIAN_LANGUAGES = [
  "Hindi",
  "English",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Punjabi",
  "Malayalam",
  "Assamese",
];

function AstrologerProfile() {
  const { token } = useAuth();
  const [profile, setProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [aboutImages, setAboutImages] = useState([]);
  const [initialProfile, setInitialProfile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { specializations } = useSpecializations();
  const { validateProfile } = useValidation(profile);

  const fetchProfile = async () => {
    try {
      const response = await api.get(`/astrologers/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data);
      setInitialProfile(response.data); // Save initial profile state
    } catch (error) {
      setError("Failed to fetch profile. Please try again later.");
      console.error("Error fetching profile:", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSpecializationChange = (index, checked) => {
    setProfile((prevProfile) => {
      const updatedSpecializations = [...prevProfile.specializations];
      if (checked) {
        updatedSpecializations.push(specializations[index]);
      } else {
        const specIndex = updatedSpecializations.findIndex(
          (s) => s.name === specializations[index].name
        );
        updatedSpecializations.splice(specIndex, 1);
      }
      return {
        ...prevProfile,
        specializations: updatedSpecializations,
      };
    });
  };

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleAboutImagesChange = (e) => {
    setAboutImages(Array.from(e.target.files));
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    const formData = new FormData();
    let hasChanges = false;

    // Append only changed fields
    if (profile.name !== initialProfile.name) {
      formData.append("name", profile.name);
      hasChanges = true;
    }
    if (profile.gender !== initialProfile.gender) {
      formData.append("gender", profile.gender);
      hasChanges = true;
    }
    if (profile.email !== initialProfile.email) {
      formData.append("email", profile.email);
      hasChanges = true;
    }
    if (profile.dob !== initialProfile.dob) {
      formData.append("dob", profile.dob);
      hasChanges = true;
    }
    if (profile.phoneNumber !== initialProfile.phoneNumber) {
      formData.append("phoneNumber", profile.phoneNumber);
      hasChanges = true;
    }
    if (profile.experience !== initialProfile.experience) {
      formData.append("experience", profile.experience);
      hasChanges = true;
    }

    if (profile.languages.join(", ") !== initialProfile.languages.join(", ")) {
      formData.append("languages", profile.languages.join(", "));
      hasChanges = true;
    }
    if (profile.aboutMe !== initialProfile.aboutMe) {
      formData.append("aboutMe", profile.aboutMe);
      hasChanges = true;
    }

    if (profile.availability !== initialProfile.availability) {
      formData.append("availability", profile.availability);
      hasChanges = true;
    }

    if (profileImage) {
      formData.append("profileImage", profileImage);
      hasChanges = true;
    }

    if (aboutImages.length > 0) {
      aboutImages.forEach((image) => {
        formData.append("aboutImages", image); // Use "aboutImages" directly without index
      });
      hasChanges = true;
    }

    if (profile.promotionalVideo !== initialProfile.promotionalVideo) {
      formData.append("promotionalVideo", profile.promotionalVideo);
      hasChanges = true;
    }

    profile.specializations.forEach((spec, index) => {
      if (!initialProfile.specializations.some((s) => s.name === spec.name)) {
        hasChanges = true;
      }
      formData.append(`specializations[${index}]`, spec.name);
    });

    if (!hasChanges) {
      setError("No changes to update.");
      setIsLoading(false);
      return;
    }

    const validationErrors = validateProfile(formData);
    setValidationErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      setError("");
      setSuccess("");
      await api.patch(`/astrologers/profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess("Profile updated successfully.");
      setInitialProfile(profile); // Update initialProfile state
      setIsEditing(false);
      fetchProfile();
    } catch (error) {
      setError(
        error?.response?.data?.message ||
          "Failed to update profile. Please try again later."
      );
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageDelete = async (imageName) => {
    try {
      await api.delete(`/astrologers/profile/images`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { imageName },
      });
      setSuccess("Image deleted successfully.");
      fetchProfile();
    } catch (error) {
      setError(
        error?.response?.data?.message ||
          "Failed to delete image. Please try again later."
      );
      console.error("Error deleting image:", error);
    }
  };

  if (!profile)
    return <div className="text-center text-gray-600 min-h-36">Loading...</div>;

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 max-w-4xl">
      <div className="bg-white shadow-lg rounded-lg p-4 sm:p-6 lg:p-8 border border-gray-200">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 border-b border-gray-300 pb-2 sm:pb-3">
          Astrologer Profile
        </h1>
        <div className="flex flex-col gap-4">
          <div className="flex-1 flex flex-col items-center">
            <img
              src={"https://astrologerdaddy.com/api/" + profile.profileImage}
              alt={profile.name}
              className="w-40 h-40 sm:w-48 sm:h-48 md:w-64 md:h-64 object-cover mx-auto rounded-full border border-gray-300"
            />
            {isEditing && (
              <input
                type="file"
                accept="image/*"
                onChange={handleProfileImageChange}
                className="mt-4 bg-white border border-gray-300 rounded p-2 cursor-pointer"
              />
            )}
          </div>
          <div className="flex-1">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Personal Information
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                {
                  icon: <FaUser className="text-gray-600" />,
                  label: "Name",
                  name: "name",
                  type: "text",
                  value: profile.name,
                },
                {
                  icon: <FaTransgender className="text-gray-600" />,
                  label: "Gender",
                  name: "gender",
                  type: "text",
                  value: profile.gender,
                },
                {
                  icon: <FaEnvelope className="text-gray-600" />,
                  label: "Email",
                  name: "email",
                  type: "email",
                  value: profile.email,
                },
                {
                  icon: <FaCalendarAlt className="text-gray-600" />,
                  label: "Date of Birth",
                  name: "dob",
                  type: "date",
                  value: new Date(profile.dob).toISOString().split("T")[0],
                },
                {
                  icon: <FaPhone className="text-gray-600" />,
                  label: "Phone Number",
                  name: "phoneNumber",
                  type: "text",
                  value: profile.phoneNumber,
                  disabled: true,
                },
                {
                  icon: <FaStar className="text-gray-600" />,
                  label: "Experience",
                  name: "experience",
                  type: "number",
                  value: profile.experience,
                },
              ].map(({ icon, label, name, type, value, disabled }) => (
                <div key={name} className="mb-4">
                  <label className="flex items-center mb-2 text-lg font-medium">
                    {icon && <span className="mr-3">{icon}</span>}
                    {label}:
                  </label>
                  {isEditing ? (
                    <>
                      <input
                        type={type}
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        className="w-full border rounded p-3"
                        disabled={disabled}
                      />
                      {validationErrors[name] && (
                        <div className="text-red-500">
                          {validationErrors[name]}
                        </div>
                      )}
                    </>
                  ) : (
                    <p className="text-gray-700">{value}</p>
                  )}
                </div>
              ))}

              <div className="mb-4">
                <label className="flex items-center mb-2 text-lg font-medium">
                  <FaLanguage className="text-gray-600 mr-3" />
                  Languages:
                </label>
                <div className="grid grid-cols-2 gap-4">
                  {isEditing ? (
                    TOP_INDIAN_LANGUAGES.map((lang) => (
                      <div key={lang} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={profile.languages.includes(lang)}
                          onChange={(e) =>
                            setProfile((prevProfile) => ({
                              ...prevProfile,
                              languages: e.target.checked
                                ? [...prevProfile.languages, lang]
                                : prevProfile.languages.filter(
                                    (l) => l !== lang
                                  ),
                            }))
                          }
                          className="mr-2"
                        />
                        {lang}
                        {validationErrors.languages && (
                          <div className="text-red-500">
                            {validationErrors.languages}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-700">
                      {profile.languages.join(", ")}
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <label className="flex items-center mb-2 text-lg font-medium">
                  <FaBriefcase className="text-gray-600 mr-3" />
                  Specializations:
                </label>
                <div className="grid grid-cols-2 gap-4">
                  {isEditing ? (
                    specializations.map((spec, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={profile.specializations.some(
                            (s) => s.name === spec.name
                          )}
                          onChange={(e) =>
                            handleSpecializationChange(index, e.target.checked)
                          }
                          className="mr-2"
                        />
                        {spec.name}
                        {validationErrors.specializations && (
                          <div className="text-red-500">
                            {validationErrors.specializations}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-700">
                      {profile.specializations.map((s) => s.name).join(", ")}
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaShieldAlt className="text-gray-700" />
                <strong className="text-lg font-medium">
                  Verification Status:
                </strong>{" "}
                <span
                  className={`font-semibold capitalize ${
                    profile.verified === "approved"
                      ? "text-green-600"
                      : profile.verified === "rejected"
                      ? "text-red-600"
                      : "text-yellow-600"
                  }`}
                >
                  {profile.verified}
                </span>
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaStar className="text-gray-700" />
                <strong className="text-lg font-medium">Rating:</strong>{" "}
                {profile.averageRating || 0}
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaCalendarCheck className="text-gray-700" />
                <strong className="text-lg font-medium">
                  Availability:
                </strong>{" "}
                {profile.availability ? "Available" : "Not Available"}
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaClock className="text-gray-700" />
                <strong className="text-lg font-medium">
                  Minimum Call Duration:
                </strong>{" "}
                {profile.minimumCallDuration} minutes
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaRupeeSign className="text-gray-700" />
                <strong className="text-lg font-medium">
                  Minute Price:
                </strong>{" "}
                {profile.minutePrice} ₹
              </div>

              <div className="mb-4 flex items-center gap-2">
                <FaWallet className="text-gray-700" />
                <strong className="text-lg font-medium">Wallet:</strong>{" "}
                {profile.wallet.balance || 0} ₹
              </div>
            </div>

            <div className="mb-4">
              <label className="flex items-center mb-2 text-lg font-medium">
                <FaUserCircle className="text-gray-600 mr-3" />
                About Me:
              </label>
              <textarea
                name="aboutMe"
                value={profile.aboutMe}
                onChange={handleInputChange}
                className={`w-full border rounded p-3 resize-none ${
                  isEditing ? "" : "bg-gray-100 cursor-not-allowed"
                }`}
                disabled={!isEditing}
                rows={5}
              />
              {validationErrors.aboutMe && (
                <div className="text-red-500">{validationErrors.aboutMe}</div>
              )}
            </div>
            {profile.aboutImages && profile.aboutImages.length > 0 && (
              <div className="flex gap-2 flex-wrap mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg mb-4">
                {profile.aboutImages.map((img, index) => (
                  <div key={index} className="relative">
                    <img
                      src={"https://astrologerdaddy.com/api/" + img}
                      alt={`About ${index}`}
                      className="w-full h-32 object-cover rounded border border-gray-300"
                    />
                    {isEditing && (
                      <button
                        onClick={() => handleImageDelete(img)}
                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                      >
                        <FaTrash />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}

            {isEditing && (
              <div className="mb-4">
                <label className="flex items-center mb-2 text-lg font-medium">
                  <FaImage className="text-gray-600 mr-3" />
                  About Images:
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleAboutImagesChange}
                  className="w-full border rounded p-3"
                />
              </div>
            )}

            {isEditing && (
              <div className="mb-4">
                <label className="flex items-center mb-2 text-lg font-medium">
                  <FaVideo className="text-gray-600 mr-3" />
                  Promotional Video (Youtube VIDEO ID):
                </label>
                <input
                  type="text"
                  className="w-full border rounded p-3"
                  onChange={handleInputChange}
                  name="promotionalVideo"
                  value={profile.promotionalVideo}
                  disabled={!isEditing}
                  placeholder="https://www.youtube.com/watch?v=your-video-id"
                />
              </div>
            )}

            <div className="flex gap-4">
              <button
                onClick={() => {
                  setIsEditing(!isEditing);
                  setError("");
                  setSuccess("");
                }}
                className={`px-4 py-2 flex gap-4 w-fit items-center justify-center text-sm sm:text-base rounded text-white ${
                  isEditing ? "bg-red-600" : "bg-orange-600"
                } hover:bg-opacity-80`}
              >
                <FaEdit /> {isEditing ? "Cancel" : "Edit"}
              </button>

              {isEditing && (
                <button
                  onClick={handleSaveChanges}
                  className="px-4 py-2 flex gap-4 w-fit items-center justify-center text-sm sm:text-base bg-orange-600 text-white rounded hover:bg-opacity-80"
                >
                  <FaSave />
                  {isLoading ? "Saving..." : "Save Changes"}
                </button>
              )}
            </div>

            {error && (
              <div className="text-red-600 mt-4 text-center">{error}</div>
            )}
            {success && (
              <div className="text-green-600 mt-4 text-center">{success}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AstrologerProfile;
