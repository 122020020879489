import React, { useState, useEffect } from "react";
import AstrologerCard from "../components/AstrologerCard";
import api from "../api";
import TOP_INDIAN_LANGUAGES from "../constants/languages";
import useSpecializations from "../hooks/useSpecializations";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import customSelectStyles from "../styles/react-select";

const animatedComponents = makeAnimated();

const Astrologers = () => {
  const [astrologers, setAstrologers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("asc");

  const { specializations, error: specializationsError } = useSpecializations();

  useEffect(() => {
    const fetchAstrologers = async () => {
      setLoading(true);
      try {
        const response = await api.get("/astrologers", {
          params: {
            page,
            limit: 12,
            search,
            specializations: selectedSpecializations
              .map((s) => s.value)
              .join(","),
            languages: languages.map((l) => l.value).join(","),
            sort,
            order,
          },
        });

        if (response.data) {
          setAstrologers(response.data.astrologers || []);
          setTotalPages(response.data.totalPages || 1);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching astrologers:", error);
        setLoading(false);
      }
    };
    fetchAstrologers();
  }, [page, search, selectedSpecializations, languages, sort, order]);

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handlePageChange = (newPage) => setPage(newPage);

  const handleSpecializationChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setSelectedSpecializations(selectedOptions || []);
    }
  };

  const handleLanguageChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      setSelectedLanguages(selectedOptions || []);
      setLanguages(selectedOptions || []);
    }
  };

  const handleSortChange = (selectedOption) => {
    const [sortField, sortOrder] = selectedOption.value.split(":");
    setSort(sortField);
    setOrder(sortOrder);
  };

  const specializationOptions = specializations.map((sp) => ({
    value: sp._id,
    label: sp.name,
  }));
  const languageOptions = TOP_INDIAN_LANGUAGES.map((lang) => ({
    value: lang,
    label: lang,
  }));

  const sortOptions = [
    { value: "rating:asc", label: "Rating (Low to High)" },
    { value: "rating:desc", label: "Rating (High to Low)" },
    { value: "minutePrice:asc", label: "Price (Low to High)" },
    { value: "minutePrice:desc", label: "Price (High to Low)" },
    { value: "experience:asc", label: "Experience (Low to High)" },
    { value: "experience:desc", label: "Experience (High to Low)" },
    { value: "minimumCallDuration:asc", label: "Min Duration (Low to High)" },
    { value: "minimumCallDuration:desc", label: "Min Duration (High to Low)" },
  ];

  return (
    <div className="md:w-5/6 w-11/12 mx-auto py-8 px-4 md:px-2">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-semibold mb-2 text-gray-800">
          Find Your Astrologer
        </h1>
        <p className="text-gray-600">
          Search through our expert astrologers to find the right one for you.
        </p>
      </div>

      {/* Filter Section */}
      <div className="mb-4 space-y-4 md:space-y-0 md:grid md:grid-cols-4 md:gap-4 md:items-center">
        <div className="filter-container">
          <label className="filter-label font-medium">Search:</label>
          <input
            type="text"
            placeholder="Search by name"
            value={search}
            onChange={handleSearchChange}
            className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 transition duration-150 ease-in-out"
            style={{ height: "54px" }} // Match height of react-select
          />
        </div>

        <div className="filter-container">
          <label className="filter-label font-medium">
            Specializations{" "}
            {selectedSpecializations.length > 0
              ? `${selectedSpecializations.length} / 3`
              : ""}
          </label>
          <Select
            isMulti
            value={selectedSpecializations}
            onChange={handleSpecializationChange}
            options={specializationOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            isOptionDisabled={(option) =>
              selectedSpecializations.length >= 3 &&
              !selectedSpecializations.includes(option)
            }
            styles={customSelectStyles}
          />
          {specializationsError && (
            <p className="text-red-500 mt-2 text-sm">
              Error loading specializations
            </p>
          )}
        </div>

        <div className="filter-container">
          <label className="filter-label font-medium">
            Languages{" "}
            {selectedLanguages.length > 0
              ? `${selectedLanguages.length} / 3`
              : ""}
          </label>
          <Select
            isMulti
            value={languages}
            onChange={handleLanguageChange}
            options={languageOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            isOptionDisabled={(option) =>
              selectedLanguages.length >= 3 &&
              !selectedLanguages.includes(option)
            }
            styles={customSelectStyles}
          />
        </div>

        <div className="filter-container">
          <label className="filter-label font-medium">Sort by:</label>
          <Select
            value={sortOptions.find(
              (option) => option.value === `${sort}:${order}`
            )}
            onChange={handleSortChange}
            options={sortOptions}
            components={animatedComponents}
            className="filter-select"
            classNamePrefix="select"
            styles={customSelectStyles}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-yellow-500"></div>
        </div>
      ) : (
        <>
          {astrologers.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {astrologers.map((astrologer) => (
                <AstrologerCard
                  key={astrologer._id}
                  avatar={astrologer.profileImage}
                  name={astrologer.name}
                  id={astrologer._id}
                  ratings={astrologer.ratings}
                  experience={astrologer.experience}
                  category={astrologer.category}
                  languages={astrologer.languages}
                  specializations={astrologer.specializations}
                  minutePrice={astrologer.minutePrice}
                  minimumCallDuration={astrologer.minimumCallDuration}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500 mt-12">
              No astrologers found. Try adjusting your search.
            </p>
          )}
        </>
      )}

      <div className="mt-8 flex justify-center gap-2">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 border border-gray-300 rounded-lg transition-colors duration-300 ${
              page === index + 1
                ? "bg-yellow-500 text-white"
                : "bg-white text-gray-600 hover:bg-yellow-100"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Astrologers;
