import React from "react";
import BannerSection from "../sections/BannerSection";
import ZodiacSection from "../sections/ZodiacSection";
import OurAstrologersSection from "../sections/OurAstrologersSection";
import AdSection from "../sections/AdSection";
import CustomerStories from "../sections/CustomerStories";
import AboutSection from "../sections/AboutSection";


function Home() {
  return (
    <>
      <BannerSection />
      <ZodiacSection />
      <OurAstrologersSection />
      <AdSection />
      <AboutSection/>
      <CustomerStories />
      
    </>
  );
}

export default React.memo(Home)
