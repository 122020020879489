import React, { createContext, useState, useContext } from "react";
import { StreamChat } from "stream-chat";
const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(
    StreamChat.getInstance("gswhjwekwcfc", {
      timeout: 6000,
    })
  );
  const [channel, setChannel] = useState(null);

  return (
    <ChatContext.Provider
      value={{ chatClient, setChatClient, channel, setChannel }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
