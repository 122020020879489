import React from "react";
import { FaPhoneSlash } from "react-icons/fa";
import { useCall } from "@stream-io/video-react-sdk";
function MyCallEndButton() {
  const call = useCall();

  return (
    <button
      onClick={async () => {
        await call.endCall();
      }}
      className="bg-red-600 hover:bg-red-700 text-white p-4 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
    >
      <FaPhoneSlash size="24" />
    </button>
  );
}

export default MyCallEndButton;
