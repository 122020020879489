import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaLock, FaPhone } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import api from "../api";

function LoginAstrologer() {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [orderId, setOrderId] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(number);
  };

  const handleSendOtp = async () => {
    if (!validatePhoneNumber(phone)) {
      setError("Please enter a valid phone number.");
      return;
    }
    try {
      const response = await api.post("/auth/generate-otp", { phone });
      if (response.status === 200) {
        setShowOtpInput(true);
        setSuccess("OTP sent successfully!");
        setError("");
        setOrderId(response.data.data.orderId);
      } else {
        setError("Failed to send OTP.");
      }
    } catch (err) {
      console.error("Error sending OTP:", err);
      if (err.response.data.message) {
        setError(err.response.data.message);
        return;
      }
      setError("An error occurred while verifying OTP.");
      if (err.response.data.isOTPVerified === false) {
        setError("Incorrect OTP");
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 6) {
      setError("Please enter a valid OTP.");
      setSuccess("");
      return;
    }
    try {
      const response = await api.post("/astrologers/verify-otp", {
        phoneNumber: phone,
        otp,
        orderId,
      });
      if (response.status === 200) {
        setSuccess("OTP verified successfully!");
        setError("");
        login(
          response.data.token,
          response.data.user,
          response.data.userCallToken
        );
        setTimeout(() => {
          navigate("/astrologer/profile");
        }, 1500);
      } else {
        setError("Failed to verify OTP.");
      }
    } catch (err) {
      setError("An error occurred while verifying OTP.");
      if (err.response.data.isOTPVerified === false) {
        setError("Incorrect OTP");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[82vh] bg-gradient-to-r from-orange-400 to-yellow-400 px-4">
      <div className="p-8 bg-white shadow-lg rounded-lg max-w-md w-full">
        {!showOtpInput ? (
          <div>
            <div className="mb-4">
              <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2 font-bold text-2xl">
                  Astrologer Portal
                </p>
                <p className="text-gray-700 mb-2 font-bold text-xl">
                  Login to Astrologer Daddy
                </p>
                <p className="text-gray-600 font-semibold">
                  Please enter your phone number to receive an OTP.
                </p>
              </div>
              <label className="block text-gray-700">Phone Number</label>
              <div className="flex items-center border border-gray-300 p-2 rounded">
                <span className="text-gray-500 mr-2">+91</span>
                <FaPhone className="text-gray-500 mr-2" />
                <input
                  type="text"
                  className="flex-1 focus:outline-none"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                />
              </div>
            </div>
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {success && <div className="mb-4 text-green-500">{success}</div>}
            <button
              onClick={handleSendOtp}
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition duration-200"
            >
              Send OTP
            </button>
          </div>
        ) : (
          <div>
            <div className="mb-4">
              <div className="mb-4 text-center">
                <p className="text-gray-700 mb-2 font-bold text-xl">
                  OTP Verification
                </p>
                <p className="text-gray-600 font-semibold">
                  Please enter the OTP sent to your phone number.
                </p>
              </div>
              <label className="block text-gray-700">Enter OTP</label>
              <div className="flex items-center border border-gray-300 p-2 rounded">
                <FaLock className="text-gray-500 mr-2" />
                <input
                  type="text"
                  className="flex-1 focus:outline-none"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                />
              </div>
            </div>
            <button
              onClick={handleVerifyOtp}
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition duration-200"
            >
              Verify OTP
            </button>
            {error && <div className="mb-4 text-red-500">{error}</div>}
            {success && <div className="mb-4 text-green-500">{success}</div>}
          </div>
        )}
        <div className="flex justify-between mt-4 text-sm text-gray-700 gap-2 flex-wrap">
          <button
            onClick={() => navigate("/register")}
            className="hover:underline underline lg:no-underline"
          >
            Register as Astrologer
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginAstrologer;
