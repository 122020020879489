// AuthContext.js
import { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem("astrologer_daddy_token_astrologer")
  );
  const [userCallToken, setUserCallToken] = useState(
    localStorage.getItem("userCallToken")
  );
  const [user, setUser] = useState({});

  const login = (token, user, userCallToken) => {
    localStorage.setItem("astrologer_daddy_token_astrologer", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userCallToken", userCallToken);
    setUser(user);
    setToken(token);
    setUserCallToken(userCallToken);
  };

  const logout = () => {
    localStorage.removeItem("astrologer_daddy_token_astrologer");
    localStorage.removeItem("user");
    localStorage.removeItem("userCallToken");
    setToken(null);
    setUserCallToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, user, login, logout, userCallToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
