import React, { useState } from "react";
import { FaStar, FaPhoneAlt, FaComments } from "react-icons/fa";
import api from "../api";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../components/Spinner"; // Assuming you have a Spinner component
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function AstrologerCard({
  avatar,
  name,
  id,
  rating,
  experience,
  languages,
  specializations,
  minimumCallDuration,
  minutePrice,
  showCallChat = true,
}) {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { token } = useAuth();

  const navigate = useNavigate();

  const stars = Array.from({ length: 5 }, (v, i) => (
    <FaStar
      key={i}
      className={`${
        i < rating ? "text-yellow-300" : "text-gray-300"
      } inline-block text-xs sm:text-sm md:text-lg`}
    />
  ));

  function onCardClickHandler(id) {
    navigate(`/astrologer/${id}`);
  }

  return (
    <div className="w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
      <div className="flex gap-2 px-4 py-6 w-full">
        <div className="flex flex-col items-center w-fit">
          <div
            className="cursor-pointer"
            onClick={() => onCardClickHandler(id)}
          >
            <img
              className="rounded-full w-10 h-10 md:h-16 md:w-16 xl:w-20 xl:h-20 object-cover hover:opacity-85"
              src={"https://astrologerdaddy.com/api/" + avatar}
              alt={name.split(" ")[0]}
            />
          </div>
          <div className="flex gap-1 items-center mt-2">{stars}</div>

          <p className="mt-4 text-xs sm:text-sm lg:text-base text-gray-600 font-thin">
            Price: ₹{minutePrice}/min
          </p>
        </div>

        {/* Second div: Name, Languages, Experience, Specializations, and Price */}
        <div className="flex flex-wrap flex-col justify-center w-fit">
          <div
            className="cursor-pointer "
            onClick={() => onCardClickHandler(id)}
          >
            <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-gray-800 hover:text-gray-500">
              {name.split(" ")[0]}
            </h2>
          </div>
          <p className="mt-2 text-gray-600 text-sm lg:text-base font-normal">
            {languages.join(", ")}
          </p>
          <p className="text-gray-600 text-sm lg:text-base font-normal">
            Exp: {experience} years
          </p>

          <p className="text-gray-600 text-sm lg:text-base font-normal truncate w-4/6 md:w-5/6 pr-2 overflow-hidden whitespace-nowrap">
            {specializations.map((specialization, index) => (
              <span key={index}>
                {specialization.name}
                {index !== specializations.length - 1 && ", "}
              </span>
            ))}
          </p>

          {showCallChat && (
            <div className="px-3 py-1 flex gap-2 justify-between flex-wrap">
              <button
                className="flex grow items-center justify-center px-2 py-2 border-2 text-center text-red-600 font-semibold rounded-lg  focus:outline-none"
                disabled={loading}
              >
                <FaPhoneAlt className="mr-2" />
                Call
                {loading && <Spinner className="ml-2" />}
              </button>
              <button className="flex grow items-center justify-center px-2 py-2 border-2 rounded-lg text-center text-red-600 font-semibold  focus:outline-none">
                <FaComments className="mr-2" />
                Chat
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AstrologerCard;
