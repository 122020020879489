import React from "react";

const Disclaimer = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">Disclaimer</h1>
      <p className="mb-6 text-gray-700">
        Welcome to BK Astro Service Private Limited. By accessing and using our
        website, services, and content, you agree to the following terms and
        conditions outlined in this disclaimer. Please read carefully.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        General Information
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited provides astrological services,
        including Vedic astrology, Tarot readings, Numerology, Vastu
        consultations, and related services. The content and services offered
        are for informational and entertainment purposes only. While our experts
        strive to provide accurate and insightful guidance, astrology is a
        complex and interpretive field, and results may vary.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        No Guarantees
      </h2>
      <p className="mb-6 text-gray-700">
        We make no guarantees, representations, or warranties, whether express
        or implied, about the accuracy, reliability, or completeness of any
        information provided through our services. Astrological predictions and
        advice are subject to individual interpretation, and users should use
        their discretion before making any decisions based on such information.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Professional Advice
      </h2>
      <p className="mb-6 text-gray-700">
        Astrological advice should not replace professional advice in fields
        such as medical, legal, financial, psychological, or any other
        professional domain. Always seek the advice of qualified professionals
        regarding any concerns or questions you may have in these areas.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Limitation of Liability
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited, its affiliates, and its employees are
        not liable for any direct, indirect, incidental, consequential, or
        punitive damages arising from the use of our services, including but not
        limited to financial loss, personal injury, or any other adverse
        consequences. Users assume full responsibility for their actions and
        decisions based on our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Confidentiality and Privacy
      </h2>
      <p className="mb-6 text-gray-700">
        We value your privacy and confidentiality. While we take measures to
        protect your personal information, we cannot guarantee complete
        security. By using our services, you acknowledge and accept the inherent
        risks of online communication and data transmission.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Changes to Services
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited reserves the right to modify, suspend,
        or discontinue any aspect of our services at any time without notice. We
        are not liable for any consequences resulting from such changes.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        User Responsibility
      </h2>
      <p className="mb-6 text-gray-700">
        Users are responsible for providing accurate and truthful information
        during consultations. Misrepresentation or providing false information
        can lead to inaccurate predictions and advice.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Third-Party Links
      </h2>
      <p className="mb-6 text-gray-700">
        Our website may contain links to third-party websites. These links are
        provided for convenience and informational purposes only. BK Astro
        Service Private Limited does not endorse or assume responsibility for
        the content, services, or privacy practices of third- party websites.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Intellectual Property
      </h2>
      <p className="mb-6 text-gray-700">
        All content, including text, graphics, logos, images, and software, on
        our website and services is the property of BK Astro Service Private
        Limited and is protected by copyright and intellectual property laws.
        Unauthorized use, reproduction, or distribution of our content is
        prohibited.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Amendments to Disclaimer
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited reserves the right to amend this
        disclaimer at any time. Changes will be effective immediately upon
        posting on our website. It is the user's responsibility to review this
        disclaimer periodically for updates.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Contact Information
      </h2>
      <p className="mb-6 text-gray-700">
        For any questions or concerns regarding this disclaimer or our services,
        please contact us at:
        <a
          href="mailto:support@astrologerdaddy.com"
          className="text-blue-600 hover:underline"
        >
          {" "}
          support@astrologerdaddy.com
        </a>
        .
      </p>

      <p className="text-gray-700">
        By using our services, you acknowledge that you have read, understood,
        and agree to this disclaimer. Thank you for choosing BK Astro Service
        Private Limited.
      </p>
    </div>
  );
};

export default Disclaimer;
