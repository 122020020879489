import { createContext, useState, useContext } from "react";

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState();

  

  return (
    <ClientContext.Provider value={{ client, setClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => useContext(ClientContext);
