import { Routes, Route, useNavigate } from "react-router-dom";
import { StreamVideo, StreamVideoClient } from "@stream-io/video-react-sdk";

import Navbar from "./components/Navbar";
import Footer from "./sections/Footer";

import Home from "./screens/Home";
import LoginAstrologer from "./screens/LoginAstrologer";
import RegisterAstrologer from "./screens/RegisterAstrologer";
import AstrologerProfile from "./screens/AstrologerProfile";
import Appointments from "./screens/Appointments";
import AudioAppointment from "./screens/AudioAppointment";
import ChatPage from "./screens/ChatPage";
import Astrologers from "./screens/Astrologers";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import Disclaimer from "./screens/Disclaimer";
import PricingPolicy from "./screens/PricingPolicy";
import AboutUs from "./screens/AboutUs";
import ScrollToTop from "./utils/ScrollToTop";
import Wallet from "./screens/Wallet";
import Astrologer from "./screens/Astrologer";
import { useAuth } from "./contexts/AuthContext";
import { useClient } from "./contexts/ClientContext";
import { useEffect } from "react";
import api from "./api";
import { useChat } from "./contexts/ChatContext";

export default function App() {
  const navigate = useNavigate();
  const { token, userCallToken } = useAuth();
  const { setClient, client } = useClient();
  
  const user = localStorage.getItem("user");

  useEffect(() => {
    async function fetchData() {
      try {
        await api.get("/astrologers/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (userCallToken) {
          const apiKey = "gswhjwekwcfc";

          let user = null;
          let userId = null;
          try {
            user = JSON.parse(localStorage.getItem("user"));
            userId = user ? user._id : null;
            user.id = userId;
            user.userToken = userCallToken;

            const client = new StreamVideoClient({
              apiKey,
              user,
              token: userCallToken,
            });

            
            setClient(client);
          } catch (error) {
            console.error("Error", error);
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        }
      }
    }
    if (token && userCallToken && user) {
      fetchData();
    }
  }, [token, userCallToken]);

  return (
    <StreamVideo client={client}>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" Component={Home}></Route>
        <Route path="/login" Component={LoginAstrologer}></Route>
        <Route path="/register" Component={RegisterAstrologer}></Route>
        <Route path="/astrologer/profile" Component={AstrologerProfile}></Route>
        <Route path="/astrologer/appointments" Component={Appointments}></Route>
        <Route
          path="/astrologer/appointment/call/:call_id"
          Component={AudioAppointment}
        ></Route>
        <Route
          path="/astrologer/appointment/chat/:chat_id"
          Component={ChatPage}
        ></Route>
        <Route path="/astrologer/wallet" Component={Wallet}></Route>
        <Route path="/astrologers" Component={Astrologers} />
        <Route path="/astrologer/:astrologer_id" Component={Astrologer} />
        <Route path="privacy-policy" Component={PrivacyPolicy} />
        <Route path="refund-policy" Component={RefundPolicy} />
        <Route path="pricing-policy" Component={PricingPolicy} />
        <Route path="terms-and-conditions" Component={TermsAndConditions} />
        <Route path="disclaimer" Component={Disclaimer} />
        <Route path="about-us" Component={AboutUs} />
      </Routes>
      <Footer />
    </StreamVideo>
  );
}
