import { useEffect } from "react";
import { MyDescriptionPanel } from "./MyDescriptionPanel";
import { MyParticipantsPanel } from "./MyParticipantsPanel";
import { MyControlsPanel } from "./MyControlsPanel";

export const CallUILayout = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="flex items-center justify-center my-10 ">
      <div className="bg-amber-500 p-8 rounded-lg shadow-xl text-center w-96">
        <MyDescriptionPanel />
        <MyParticipantsPanel />
        <MyControlsPanel />
      </div>
    </div>
  );
};
