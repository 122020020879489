import React from "react";
import * as zodiacs from "../assets/zodiacs/zodiacs";
import SectionHeading from "../components/SectionHeading";
import { useNavigate } from "react-router-dom";


const zodiacNames = [
  "aquarius",
  "aries",
  "cancer",
  "capricorn",
  "gemini",
  "leo",
  "libra",
  "pisces",
  "sagittarius",
  "scorpio",
  "taurus",
  "virgo",
];

const images = zodiacNames.map((name) => ({
  image: zodiacs[name],
  title: name,
}));

function ZodiacSection() {
  return (
    <section className="py-8 w-4/5 mx-auto">
      <SectionHeading title={"Choose your Zodiac Sign"} />
      <div className="grid py-8 items-center justify-center grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        {images.map((item) => (
          <Zodiac key={item.title} image={item.image} title={item.title} />
        ))}
      </div>
    </section>
  );
}

function Zodiac({ image, title }) {
  const navigate = useNavigate();
  return (
    <img
      src={image}
      alt={title}
      className="w-fit rounded-full my-3 cursor-pointer hover:scale-105"
      onClick={() => navigate("/login")}
    />
  );
}

export default ZodiacSection;
