import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4 text-gray-800 text-center">Privacy Policy</h1>
      <p className="mb-6 text-gray-700">
        Welcome to BK Astro Service Private Limited. These terms and conditions
        outline the rules and regulations for the use of our website and
        services. By accessing this website, we assume you accept these terms
        and conditions in full. Do not continue to use BK Astro Service Private
        Limited's website if you do not accept all of the terms and conditions
        stated on this page.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Security and Privacy
      </h2>
      <p className="mb-6 text-gray-700">
        We prioritize the security of your personal and payment information. All
        transactions are encrypted and processed through secure payment
        gateways. Your privacy is of utmost importance to us, and we do not
        share your information with third parties.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Services</h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited offers various astrology services,
        including but not limited to Vedic astrology consultations, Tarot
        readings, Numerology, Vastu consultations, Free Live astrology sessions,
        Daily horoscopes, and Free Kundli matching.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        User Eligibility
      </h2>
      <p className="mb-6 text-gray-700">
        You must be at least 18 years of age to use our services. By using our
        website and services, you represent and warrant that you are at least 18
        years old.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Account Registration
      </h2>
      <p className="mb-6 text-gray-700">
        To access certain services, you may be required to create an account.
        You agree to provide accurate, current, and complete information during
        the registration process and to update such information to keep it
        accurate, current, and complete.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        User Conduct
      </h2>
      <p className="mb-6 text-gray-700">
        You agree to use our services only for lawful purposes and in a manner
        that does not infringe the rights of, restrict, or inhibit anyone else's
        use and enjoyment of the services. Prohibited behavior includes but is
        not limited to:
      </p>
      <ul className="list-disc pl-5 mb-6 text-gray-700">
        <li>Engaging in any unlawful or fraudulent activities.</li>
        <li>Transmitting any harmful or offensive content.</li>
        <li>Harassing, abusing, or harming others.</li>
        <li>
          Impersonating any person or entity, or falsely stating or otherwise
          misrepresenting your affiliation with a person or entity.
        </li>
        <li>
          Attempting to interfere with or disrupt the operation of our services
          or servers.
        </li>
        <li>
          Collecting or storing personal data about other users without their
          explicit consent.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Payments and Refunds
      </h2>
      <p className="mb-6 text-gray-700">
        All payments for services are processed securely. By making a payment,
        you agree to our pricing and payment terms. Refunds, if applicable, will
        be issued at the discretion of BK Astro Service Private Limited and in
        accordance with our refund policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Confidentiality
      </h2>
      <p className="mb-6 text-gray-700">
        All consultations and personal information provided by users are kept
        confidential. We will not disclose your personal information to third
        parties without your explicit consent, except as required by law.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Limitation of Liability
      </h2>
      <p className="mb-6 text-gray-700">
        In no event shall BK Astro Service Private Limited, its directors,
        employees, or agents be liable for any indirect, incidental, special,
        consequential, or punitive damages, including without limitation, loss
        of profits, data, use, goodwill, or other intangible losses, resulting
        from (i) your use or inability to use the services; (ii) any
        unauthorized access to or use of our servers and/or any personal
        information stored therein; (iii) any interruption or cessation of
        transmission to or from the services; (iv) any bugs, viruses, trojan
        horses, or the like that may be transmitted to or through our services
        by any third party; or (v) any errors or omissions in any content or for
        any loss or damage of any kind incurred as a result of your use of any
        content posted, emailed, transmitted, or otherwise made available via
        the services, whether based on warranty, contract, tort, or any other
        legal theory, and whether or not we are advised of the possibility of
        such damages.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Indemnification
      </h2>
      <p className="mb-6 text-gray-700">
        You agree to indemnify, defend, and hold harmless BK Astro Service
        Private Limited, its directors, employees, and agents from and against
        any and all claims, liabilities, damages, losses, and expenses,
        including without limitation, reasonable legal and accounting fees,
        arising out of or in any way connected with your access to or use of the
        services or your violation of these terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Modifications to the Terms
      </h2>
      <p className="mb-6 text-gray-700">
        BK Astro Service Private Limited reserves the right to modify these
        terms and conditions at any time. Any changes will be effective
        immediately upon posting on our website. Your continued use of the
        services after any such modifications constitutes your acceptance of the
        new terms and conditions.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Governing Law
      </h2>
      <p className="mb-6 text-gray-700">
        These terms and conditions are governed by and construed in accordance
        with the laws of Hanumangarh Junction, Rajasthan, and you irrevocably
        submit to the exclusive jurisdiction of the courts in that location.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Contact Information
      </h2>
      <p className="mb-6 text-gray-700">
        If you have any questions or concerns about these terms and conditions,
        please contact us at {""}
        <a
          href="mailto:support@astrologerdaddy.com"
          className="text-blue-600 hover:underline"
        >
          support@astrologerdaddy.com
        </a>
        .
      </p>

      <p className="text-gray-700">
        Thank you for choosing BK Astro Service Private Limited. We look forward
        to serving you.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
