import { useState } from "react";
import api from "../api";
import { FaTimes } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function WithdrawModal({ onClose }) {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleWithdraw = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await api.post(
        "/withdrawal/settled-payments/request-withdrawal",
        { amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccess("Withdrawal request created successfully");
      setAmount("");
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <FaTimes size={18} />
        </button>
        <h2 className="text-xl font-semibold mb-4 text-center text-gray-700">
          Enter Withdrawal Amount
        </h2>
        <div className="mb-4">
          {error && <p className="text-red-500 text-center">{error}</p>}
          {success && <p className="text-green-500 text-center">{success}</p>}
        </div>
        <label
          htmlFor="amount"
          className="block text-gray-700 font-medium mb-2"
        >
          Amount
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter amount"
        />
        <button
          onClick={handleWithdraw}
          className="mt-6 bg-red-600 text-white py-2 px-6 rounded-full hover:bg-red-500 transition duration-200 w-full flex justify-center items-center"
          disabled={loading}
        >
          {loading ? (
            <AiOutlineLoading3Quarters
              className="animate-spin mr-2"
              size={18}
            />
          ) : (
            "Withdraw"
          )}
        </button>
      </div>
    </div>
  );
}

export default WithdrawModal;
